<template>
  <v-container v-if="report && report.content">
    
    <v-alert
      class="no-print col-12"
      outlined
      type="warning"
    >
      Hieronder staat de voorvertoning van het rapport dat je print. In het print-dialoogvenster kan je er ook voor
      kiezen om het rapport te "printen" naar PDF om zo een PDF-rapport te genereren. Indien gewenst kan vooraf nog een
      filter of vergelijking toegepast worden zodat deze ook op de print beschikbaar is. Kijk eerst of alle data is
      geladen voordat je het rapport print.
    </v-alert>

    <template class="col-2">
      <v-btn
        class="no-print print-button"
        width="250px"
        color="pink"
        dark
        rounded
        fixed
        bottom
        right
        fab
        @click="print"
      >
        Report Afdrukken <v-icon>mdi-printer</v-icon>
      </v-btn>
    </template>

    <div v-if="report.type === 'chapters'">
      <template v-for="(chapter) in report.content">
        <template v-for="(reportPage, reportPageIndex) in chapter.pages">
          <v-row dense v-if="reportPage" :key="reportPageIndex" class="reportpage">
            <v-col cols="12">
              <h5 class="font-weight-light mb-2 report-title">{{ report.title }} > {{ chapter.title }} > {{ reportPage.title }}</h5>
            </v-col>
            
            <template v-for="(content, i, k) in reportPage.content" >
              <v-col :cols="getSizingClass(content)" class="d-flex flex-column col-12" :key="k">
                <div :is="content.type" :content="content"></div>
              </v-col>
            </template>
          </v-row>
        </template>
      </template>
    </div>
    <div v-else>

      <template v-for="(reportPage, reportPageIndex) in report.content">
        <v-row dense v-if="reportPage" :key="reportPageIndex" class="reportpage">
          <v-col cols="12">
            <h5 class="font-weight-light mb-2 report-title">{{ report.title }} > {{ reportPage.title }}</h5>
          </v-col>
          
          <template v-for="(content, i, k) in reportPage.content" >
            <v-col :cols="getSizingClass(content)" class="d-flex flex-column col-12" :key="k">
              <div :is="content.type" :content="content"></div>
            </v-col>
          </template>
        </v-row>
      </template>
    </div>


  </v-container>
</template>


<script>
  import TextContent from '@/components/reportPageContent/TextContent.vue'
  import TranslationContent from '@/components/reportPageContent/TranslationContent.vue'
  import TableContent from '@/components/reportPageContent/TableContent.vue'
  import ListContent from '@/components/reportPageContent/ListContent.vue'
  import ProjectResponseSimple from '@/components/reportPageContent/ProjectResponseSimple.vue'
  import SingleScore from '@/components/reportPageContent/SingleScore.vue'
  import SubjectScore from '@/components/reportPageContent/SubjectScore.vue'
  import NpsScore from '@/components/reportPageContent/NpsScore.vue'
  import OpenQuestion from '@/components/reportPageContent/OpenQuestion.vue'
  import CountPerScore from '@/components/reportPageContent/CountPerScore.vue'
  import CountPerScoreNoGrade from '@/components/reportPageContent/CountPerScoreNoGrade.vue'
  import CountPerScoreRound from '@/components/reportPageContent/CountPerScoreRound.vue'
  import CustomDivideHundred from '@/components/reportPageContent/CustomDivideHundred.vue'
  import YesNo from '@/components/reportPageContent/YesNo.vue'
  // New visualisation components
  import TableDistribution from '@/components/reportPageContent/TableDistribution.vue'
  import ChooseBetweenTwo from '@/components/reportPageContent/ChooseBetweenTwo.vue'
  import ProjectResponse from '@/components/reportPageContent/ProjectResponseV2.vue'
  import CustomSelect from '@/components/reportPageContent/CustomSelectV2.vue'

  export default {
    name: "ReportPage",
    data () {
      return {
        report: undefined,
      }
    },
    components: {
      TextContent,
      TranslationContent,
      TableContent,
      TableDistribution,
      ChooseBetweenTwo,
      ListContent,
      ProjectResponse,
      ProjectResponseSimple,
      SingleScore,
      SubjectScore,
      NpsScore,
      OpenQuestion,
      CountPerScore,
      CountPerScoreNoGrade,
      CountPerScoreRound,
      CustomSelect,
      CustomDivideHundred,
      YesNo
    },
    methods: {
      getReport() {
        this.report = this.$store.state.reports.find(report => report.slug === this.$route.params.report)

        console.log(this.report)
        
        // Update the current report
        this.$store.commit('setCurrentReport', this.report.slug)

        if (this.report.settings) {
          this.$store.commit('setReportSettings', this.report.settings)
        } else {
          this.$store.commit('setReportSettings', {})
        }

        // window.print()
        // this.$router.go(-1)
      },
      print() {
        window.print()
      },
      getSizingClass(content) {
        if (content.size === undefined) {
          return "sm-12"
        }
        else if (content.size === "half") {
          return "sm-6"
        }
        else if (content.size === "third") {
          return "sm-4"
        }
        else if (content.size === "quarter") {
          return "sm-3"
        }
        else if (content.size === "full") {
          return "sm-12"
        }
        else if (content.size === "twothird") {
          return "sm-8"
        }
        else if (content.size === "threequarter") {
          return "sm-9"
        }
        else if (content.size.startsWith("sm")) {
          return content.size
        }
        return "sm-12"
      }
    },
    mounted() {
      this.getReport()
    },
    watch: {
      '$route': function () { this.getReport() },
      '$i18n.locale': function () { this.getReport() }
    }
  }
</script>